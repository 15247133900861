var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("h1", [_vm._v("Import Issues")]),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("PV Watts")]),
          _c(
            "v-card-text",
            [
              _vm._v(
                " " + _vm._s(_vm.pvWattsRateLimit) + " PV Watts rate limit "
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.getPvWattsRateLimit()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-cached")])],
                1
              ),
              _c("br"),
              _vm._v(
                " " +
                  _vm._s(_vm.pvWattsRateRemaining) +
                  " PV Watts rate remaining "
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.getPvWattsRateRemaining()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-cached")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Missing Site Geo")]),
          _c(
            "v-card-text",
            [
              _vm._v(_vm._s(_vm.missingSiteGeo) + " Missing Site Geo records "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.getMissingSiteGeo()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-cached")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Missing Inverter TOF")]),
          _c(
            "v-card-text",
            [
              _vm._v(
                _vm._s(_vm.missingInverterTof) +
                  " Missing Inverter TOF records "
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.getMissingInverterTof()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-cached")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0 },
                  on: {
                    click: function ($event) {
                      return _vm.reloadInverterTof()
                    },
                  },
                },
                [_vm._v("Reload Inverter TOF")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Missing Site TOF")]),
          _c(
            "v-card-text",
            [
              _vm._v(_vm._s(_vm.missingSiteTof) + " Missing Site TOF records "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.getMissingSiteTof()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-cached")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0 },
                  on: {
                    click: function ($event) {
                      return _vm.reloadSiteTof()
                    },
                  },
                },
                [_vm._v("Reload Site TOF")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }