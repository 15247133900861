<template>
  <div>
    <br />
    <h1>Import Issues</h1>
    <v-card>
      <v-card-title>PV Watts</v-card-title>
      <v-card-text>
        {{ pvWattsRateLimit }} PV Watts rate limit
        <v-btn icon color="green" v-on:click="getPvWattsRateLimit()"
          ><v-icon>mdi-cached</v-icon></v-btn
        >
        <br />
        {{ pvWattsRateRemaining }} PV Watts rate remaining
        <v-btn icon color="green" v-on:click="getPvWattsRateRemaining()"
          ><v-icon>mdi-cached</v-icon></v-btn
        >
      </v-card-text>
    </v-card>
    <br />
    <v-card>
      <v-card-title>Missing Site Geo</v-card-title>
      <v-card-text
        >{{ missingSiteGeo }} Missing Site Geo records
        <v-btn icon color="green" v-on:click="getMissingSiteGeo()"
          ><v-icon>mdi-cached</v-icon></v-btn
        ></v-card-text
      >
      <!-- <v-card-actions>
        <v-btn flat v-on:click="reloadInverterTof()">Reload Inverter TOF</v-btn>
      </v-card-actions>       -->
    </v-card>
    <br />
    <v-card>
      <v-card-title>Missing Inverter TOF</v-card-title>
      <v-card-text
        >{{ missingInverterTof }} Missing Inverter TOF records
        <v-btn icon color="green" v-on:click="getMissingInverterTof()"
          ><v-icon>mdi-cached</v-icon></v-btn
        ></v-card-text
      >
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          v-on:click="reloadInverterTof()"
          >Reload Inverter TOF</v-btn
        >
      </v-card-actions>
    </v-card>
    <br />
    <v-card>
      <v-card-title>Missing Site TOF</v-card-title>
      <v-card-text
        >{{ missingSiteTof }} Missing Site TOF records
        <v-btn icon color="green" v-on:click="getMissingSiteTof()"
          ><v-icon>mdi-cached</v-icon></v-btn
        ></v-card-text
      >
      <v-card-actions>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          v-on:click="reloadSiteTof()"
          >Reload Site TOF</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  data() {
    return {
      missingInverterTof: null,
      missingSiteTof: null,
      missingSiteGeo: null,
      pvWattsRateLimit: null,
      pvWattsRateRemaining: null,
    };
  },
  mounted() {
    document.title = "Admin"
  },
  methods: {
    initialize() {
      console.log("initialize...");
      this.getMissingInverterTof();
      this.getMissingSiteTof();
      this.getMissingSiteGeo();
      this.getPvWattsRateLimit();
      this.getPvWattsRateRemaining();
    },
    getPvWattsRateLimit() {
      Service.getPvWattsRateLimit()
        .then((response) => {
          this.pvWattsRateLimit = response.data.ratelimit;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.error(error);
        });
    },
    getPvWattsRateRemaining() {
      Service.getPvWattsRateRemaining()
        .then((response) => {
          this.pvWattsRateRemaining = response.data.remaining;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.error(error);
        });
    },
    getMissingInverterTof() {
      Service.missingInverterTof()
        .then((response) => {
          this.missingInverterTof = response.data.missing_tof;
          console.log(this.missingInverterTof);
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading missingInverterTOF`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    reloadInverterTof() {
      Service.reloadInverterTof()
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading reloadInverterTOF`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    getMissingSiteGeo() {
      Service.missingSiteGeo()
        .then((response) => {
          this.missingSiteGeo = response.data.missing_geom;
          console.log(this.missingSiteGeo);
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading missingSiteGeo`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    getMissingSiteTof() {
      Service.missingSiteTof()
        .then((response) => {
          this.missingSiteTof = response.data.missing_tof;
          console.log(this.missingSiteTof);
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading missingSiteTOF`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    reloadSiteTof() {
      Service.reloadSiteTof()
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading reloadSiteTOF`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
  },
  computed: {},
  created() {
    this.initialize();
  },
};
</script>

<style>
</style>